.our-services {
  padding-top: 96px;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 70px;
  text-align: center;
  height: 100%;
  background: #F5F8FA;
}

.services-title {
  color: #333333;
  font-size: 42px;
  line-height: 51px;
  font-weight: 600;
}

.cards-wrapper {
  .ant-col-md-8 {
    margin-top: 35px;
  }
  .ant-card-bordered {
    height: fit-content;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
    border: 0px;
  }
}

.cards {
  margin: auto;
}

.ant-card-body {
  border-top: 1px solid transparent !important;
  padding: 20px !important;
}

.services-card {
  margin: auto !important;
  width: 90%;
  height: 350px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  height: 100% !important;
}

.card-icon-wrapper {
  height: 120px;
}

.card-icon-background {
  text-align: center;
  display: table;
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 100px;
  background: rgba(37, 61, 91, 0.2);
  margin: auto;
  img {
    position: absolute;
    top: 32px;
    left: 32px;
    width: 35px;
    height: 35px;
  }
}

.card-title-wrapper {
  padding-top: 20px;
  font-size: 26px;
  font-weight: 600;
  display: block;
  white-space: normal;
  overflow: hidden;
  p {
    text-align: center;
  }
}

.card-content-wrapper {
  padding-top: 20px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
}

//iphone x
@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
  .our-services {
    padding-left: 18px;
    padding-right: 18px;
  }
  .cards-wrapper {
    .ant-col-xs-24 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .services-title  {
    font-size: 42px;
  }
  .services-card {
    width: 100% !important;
  }
}

//galaxy fold
@media only screen and (device-width : 280px) and (device-height : 653px) {
  .our-services {
    padding-left: 18px;
    padding-right: 18px;
  }
  .cards-wrapper {
    .ant-col-xs-24 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .services-title  {
    font-size: 42px;
  }
  .services-card {
    width: 100% !important;
  }
}

//pixel 2 xl
@media only screen and (device-width : 411px) and (device-height : 823px) {
  .our-services {
    padding-left: 18px;
    padding-right: 18px;
  }
  .cards-wrapper {
    .ant-col-xs-24 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .services-title  {
    font-size: 42px;
  }
  .services-card {
    width: 100% !important;
  }
}


//ipad
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .our-services {
    padding-left: 18px;
    padding-right: 18px;
  }
  .cards-wrapper {
    .ant-col-xs-24 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .services-title  {
    font-size: 42px;
  }
  .services-card {
    width: 100% !important;
  }
}

//iphone x
@media only screen and (max-device-width : 600px) {
  .our-services {
    padding-left: 18px;
    padding-right: 18px;
  }
  .cards-wrapper {
    .ant-col-xs-24 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .services-title  {
    font-size: 42px;
  }
  .services-card {
    width: 100% !important;
  }
}
