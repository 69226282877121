.title {
  color: #333333;
  font-weight: 700;
  font-size: 42px;
  line-height: 51px;
  text-transform: uppercase;
}

.contacts {
  background-image: url("../../images/contacts-background.png") !important;
  background-repeat: no-repeat;
  background-size: auto;
  padding-top: 185px;
  padding-left: 8%;
  padding-right: 8%;
  padding-bottom: 70px;
  height: 100%;
  background: #F5F8FA;
}

.text-contacts {
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  font-weight: 300;
}

address {
  margin-top: 80px;
}

.address-row {
  margin-top: 8px;
}

.address-icons {
  margin-top: auto;
  margin-bottom: auto;
  img {
    width: 20px;
    height: 20px;
  }
}

.contact-button {
  width: 150px;
  height: 30px;
  font-size: 16px;
  line-height: 20px;
  margin-top: 100px;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
}

.address-text {
  margin-left: -20px;
}

//iphone x
@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
  .contacts {
    padding-left: 20px;
    padding-right: 20px;
  }
  .title {
    font-size: 30px;
    line-height: 51px;
  }
  .text {
    font-weight: 300 !important;
    font-size: 24px !important;
    line-height: 29px !important;
  }
  .address-text {
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .contacts {
    background-image: url("../../images/contacts-background-mobile.png") !important;
  }
}

//galaxy fold
@media only screen and (device-width : 280px) and (device-height : 653px) {
  .contacts {
    padding-left: 20px;
    padding-right: 20px;
  }
  .title {
    font-size: 30px;
    line-height: 51px;
  }
  .text {
    font-weight: 300 !important;
    font-size: 24px !important;
    line-height: 29px !important;
  }
  .address-text {
    margin-left: 0px;
  }
}

//pixel 2 xl
@media only screen and (device-width : 411px) and (device-height : 823px) {
  .contacts {
    padding-left: 20px;
    padding-right: 20px;
  }
  .title {
    font-size: 30px;
    line-height: 51px;
  }
  .text {
    font-weight: 300 !important;
    font-size: 24px !important;
    line-height: 29px !important;
  }
  .address-text {
    margin-left: 0px;
  }
}


//ipad
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .contacts {
    padding-left: 20px;
    padding-right: 20px;
  }
  .title {
    font-size: 30px;
    line-height: 51px;
  }
  .text {
    font-weight: 300 !important;
    font-size: 24px !important;
    line-height: 29px !important;
  }
  .address-text {
    margin-left: 0px;
  }
}

//iphone x
@media only screen and (max-device-width : 600px) {
  .contacts {
    padding-left: 20px;
    padding-right: 20px;
  }
  .title {
    font-size: 30px;
    line-height: 51px;
  }
  .text {
    font-weight: 300 !important;
    font-size: 24px !important;
    line-height: 29px !important;
  }
  .address-text {
    margin-left: 0px;
  }
}
