.not-found {
  padding-top: 135px;
  padding-left: 110px;
  padding-right: 110px;
  padding-bottom: 70px;
  background: #EEEFF0;
  // background-image: url("../../images/error-background.png");
  // background-repeat: no-repeat;
  // background-size: 100%;
}

.wrapper {
  width: 100%;
  height: -50%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.wrapper-2 {
  width: 100%;
  height: -50%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.error {
  font-weight: 700;
  font-size: 64px;
  line-height: 78px;
  margin-bottom: 10px;
  color: #000000;
}

.error-background {
  color: #F6F6F6;
  font-size: 288px;
  font-weight: 700;
  margin-bottom: 0;
  z-index: -1;
}

.text {
  font-weight: 700;
  font-size: 33px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 0px;
}

.button {
  margin-top: 120px;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
}

//iphone x
@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
  .error-background {
    font-size: 110px;
  }
}

//galaxy fold
@media only screen and (device-width : 280px) and (device-height : 653px) {
  .error-background {
    font-size: 110px;
  }
}

//pixel 2 xl
@media only screen and (device-width : 411px) and (device-height : 823px) {
  .error-background {
    font-size: 110px;
  }
}


//ipad
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .error-background {
    font-size: 110px;
  }
}

//iphone x
@media only screen and (max-device-width : 600px) {
  .error-background {
    font-size: 110px;
  }
}
