.footer {
  background-color: #FFFFFF;
  padding-left: 110px;
  padding-right: 110px;
  padding-top: 45px;
  padding-bottom: 20px;
  text-align: center;
  color: #000000;

  svg {
    display: inline-block;
    vertical-align: middle;
  }

  .title {
    color: #FFFFFF;
    font-size: 25px;
    font-weight: bold;
    line-height: 59px;
  }
  .text {
    color: #FFFFFF;
    font-size: 18px;
  }
}

.footer-wrapper {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.logo {
  margin: auto;
}

.contacts {
  img {
    width: 26px;
    height: 26px;
  }
}

.social {
  justify-content: flex-end;
  img {
    width: 29px;
    height: 29px;
  }
  .icon {
    margin-left: 10px;
  }
}

.follow-us {
  margin-left: -2px;
}

.copyright-wrapper {
  height: 2vh;
  padding: 8px;
  .copyright {
    color: rgba(255, 255, 255, 0.4);
  }
}

hr {
  border: 1px solid #000000;
}

.left {
  text-align: left;
}

.copyright {
  font-size: 18px;
  text-align: left;
}

$screen-md-min: 768px;

@media (max-width: $screen-md-min) {
  span.hidden-xs {
    display: none !important;
  }

  .footer {
    padding: 25px 25px;
    .logo {
      img {
        margin-top: 10%;
        margin-bottom: 10%;
        width: 250px;
      }
    }
    .social {
      margin-bottom: 2vh;
      div.left {
        svg {
          width: 10vw;
        }
      }
    }
  }
  .social {
    justify-content: flex-start;
    padding-top: 20px;
  }
}
