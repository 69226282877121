.main.mainImage {
  height: 95vh;
  margin-top: 5vh;
}

.banner-animation {
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.video-container {
  height: 100%;
  padding-top: 60px;
}

.mainImage {

  img {
    height: 100%;
    width: 100%;
  }

  img.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -138px;
    margin-left: -322px;
    width: 645px;
    height: 277px;
  }

  img.partners {
    position: absolute;
    top: 25%;
    left: 50%;
    margin-top: -138px; /* Half the height */
    margin-left: -322px;
    width: 800px;
    height: 350px;
  }
}
$screen-md-min: 768px;

@media (max-width: $screen-md-min) {
  .hidden-xs {
    display: none !important;
  }

  .hidden-md {
    display: block !important;
  }

  .mainImage {
    visibility: visible;
    height: 100vh;

    img {
      height: 100%;
      width: 100%;
    }

    img.logo {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -70px; /* Half the height */
      margin-left: -160px;
      width: 320px;
      height: 140px;
    }

    img.partners {
      position: absolute;
      top: 25%;
      left: 50%;
      margin-top: -138px; /* Half the height */
      margin-left: -322px;
      width: 800px;
      height: 350px;
    }
  }
}
