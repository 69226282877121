header {
  width: 100%;
  max-width: 1920px;
  background-color: #FFFFFF;
  position: fixed;
  z-index: 99999;
  height: 60px;
  .header-wrapper {
    height: 100%;
    width: 100%;
  }
  #logo {
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 8%;
    .icon {
      height: 30px;
    }
  }
  #menu {
    text-align: right;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 8%;
  }
  a {
    vertical-align: middle;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 29px;
    cursor: pointer;
    color: #000000;
  }

  a:hover {
    color: #000000;
  }

  a.option-title {
    font-size: 26px;
    margin-left: 10px;
    font-weight: 700;
  }

  .mr50 {
    margin-right: 50px;
  }

  .contacts {
    width: 128px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 3px;
  }
}

.burger-menu-wrapper {
  text-align: right;
  margin: auto;
  width: 100%;
  padding-right: 8%;
}

.burger-menu {
  height: 3vh;
}

.title {
  color: #000000;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.menu-hidden {
  span {
    font-size: 16px;
    line-height: 20px;
  }
}

.ant-drawer-content {
  background-image: url("../../images/contacts-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.menu-drawer {
  padding-top: 120px;
  padding-bottom: 168px;
  align-items: center;
  ul {
    list-style-type: none;
    margin-left: 20%;
    margin-right: auto;
    padding: 0;
    text-align: left;
    li {
      margin-top: 48px;
    }
    a {
      color: #333333;
      font-weight: 600;
      font-size: 68px;
      line-height: 83px;
      text-transform: uppercase;
    }
    a:link {
      text-decoration: none;
    }

    a:visited {
      text-decoration: none;
    }

    a:hover {
      text-decoration: none;
    }

    a:active {
      text-decoration: none;
    }
  }
}

.social-drawer {
  align-items: center;
}

.ant-drawer-body {
  width: 100%;
  text-align: center;
}

.center {
  text-align: center;
}

.underline {
  display: inline;
  position: relative;
  overflow: hidden;
}

.underline:after {
  content: "";
  position: absolute;
  z-index: 999;
  right: 0;
  width: 0;
  bottom: -5px;
  background: #253D5B;
  height: 6px;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.underline:hover:after,
.underline:focus:after,
.underline:active:after {
  left: 0;
  right: auto;
  width: 100%;
}

@media (max-width: 768px) {
  .ant-drawer-content {
    background-image: url("../../images/contacts-background-mobile.png");
  }
}

//iphone x
@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
  header {
    #logo {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 0px;
      margin-left: 0px;
      padding-left: 16px;
    }
    #burger-menu {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 16px;
    }
  }
  .menu-drawer {
    ul {
      margin-left: 0;
      a {
        font-size: 42px;
      }
    }
  }
}

//galaxy fold
@media only screen and (device-width : 280px) and (device-height : 653px) {
  header {
    a {
      font-size: 16px;
    }
    #logo {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 0px;
      margin-left: 0px;
      padding-left: 16px;
    }
    #burger-menu {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 16px;
    }
  }
  .menu-drawer {
    ul {
      margin-left: 0;
      a {
        font-size: 42px;
      }
    }
  }
}

//pixel 2 xl
@media only screen and (device-width : 411px) and (device-height : 823px) {
  header {
    #logo {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 0px;
      margin-left: 0px;
      padding-left: 16px;
    }
    #burger-menu {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 16px;
    }
  }
  .menu-drawer {
    ul {
      margin-left: 0;
      a {
        font-size: 42px;
      }
    }
  }
}


//ipad
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  header {
    #logo {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 0px;
      margin-left: 0px;
      padding-left: 16px;
    }
    #burger-menu {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 16px;
    }
  }
  .menu-drawer {
    ul {
      margin-left: 0;
      a {
        font-size: 42px;
      }
    }
  }
}

//iphone x
@media only screen and (max-device-width : 600px) {
  header {
    #logo {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 0px;
      margin-left: 0px;
      padding-left: 16px;
    }
    #burger-menu {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 0px;
      margin-left: 0px;
      padding-right: 16px;
    }
  }
  .menu-drawer {
    ul {
      margin-left: 0;
      a {
        font-size: 42px;
      }
    }
  }
}
